.metting-icons {
  width: 30px;
  height: 30px;
}

.metting-text {
  width: 80% !important;
  font-weight: 700 !important;
  border: 0.4px solid #fff !important;
  padding: 4px 0px;
  border-radius: 4px;
  align-items: center !important;
  justify-content: center;
  text-align: center;
}

.modal-view-metting {
  width: 80% !important;
  font-weight: 700 !important;
  padding: 4px 0px;
}

/* .card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
  z-index: revert !important;
} */

/* .css-1yjjitx-MuiSwitch-track {
  background-color: #fff !important;
} */
