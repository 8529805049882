.stickey-actions-heading {
  position: sticky;
  left: 0px;
  z-index: 0;
  color: #fff;
  background-color: #111724;
  padding-left: 10px !important;
}

.stickey-actions-body {
  position: sticky;
  left: 0px;
  z-index: 99 !important;
  background-color: #0b0f18 !important;
  padding-left: 0px !important;
}
