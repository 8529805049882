.user-stickey-actions-heading {
  position: sticky;
  left: 0px;
  z-index: 0;
  padding-left: 10px !important;
  color: #fff;
  background-color: #111724;
}

.user-stickey-actions-body {
  position: sticky;
  left: 0px;
  z-index: 9999 !important;
  background-color: #0b0f18 !important;
  padding-left: 0px !important;
}

.user-td-padding-remove {
  padding-left: 5px !important;
}

.ul-group-list {
  padding-left: 0px !important;
}

/* .card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
  z-index: revert !important;
} */

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}

.css-166bipr-Input {
  color: #fff !important;
}
.delete-icon-style {
  font-size: 40px !important;
}
.user-stickey-actions-body {
  padding-left: 10px !important;
}
.user-stickey-actions-body .MuiButtonBase-root {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
