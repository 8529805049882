.no-profile-manage {
  width: 80px;
}

.no-data {
  /* background: white; */
  height: 100%;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  color: #fff;
}
