.modal-header-box {
  background-color: #1b243a !important;
  /* border-bottom: 0.5px solid #9888885c; */
  border: none !important;
}

.modal-header-box .modal-title {
  color: #fff;
}

.detail-modal-close-btn {
  border: none;
  color: #fff !important;
  background-color: #111724 !important;
  border-radius: 50%;
}

.detail-modal-body {
  background-color: #111724 !important;
}

.detail-modal-containe .modal-content {
  border-radius: 4px !important;
  border: 0.2px solid #fff !important;
}
